import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import { NavLink, Outlet } from 'react-router-dom';
import menu from 'assets/icons/menu.svg';
import logo from 'assets/images/logo.png';
import { AuthorizationService } from "services";
import logout from "../../assets/icons/log-out.svg";
import { useEffectOnce } from 'hooks';

const drawerWidth = 240;

const MobileNavWrapper = (props: any) => {
    const { window } = props;
    const [user, setUser] = React.useState<any>({});
    const [mobileOpen, setMobileOpen] = React.useState(false);

    useEffectOnce(() => {
      const user = sessionStorage.getItem('user-profile');
      if(user) setUser(JSON.parse(user))
    });

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    function onLogoutUserClicked() {
        AuthorizationService.LogoutUser();
    }
    const drawer = (
        <div>
          <Toolbar />
          <Divider />
          <List>
          {props.NavItems.map((item:any, i: number) => (
            <NavLink key={i} to={item.itemId} className={({ isActive }) => isActive ? "activeClassName" : undefined }>
              <ListItem disablePadding>
                <ListItemButton
                  sx={{ minHeight: 48, justifyContent: mobileOpen ? 'initial' : 'center', px: 2.5 }}>
                  <ListItemIcon
                    sx={{ minWidth: 0, mr: mobileOpen ? 3 : 'auto', justifyContent: 'center' }}>
                    <img src={item.icon} alt={ item.title + " icon" } />
                  </ListItemIcon>
                  <ListItemText primary={item.title} sx={{ opacity: mobileOpen ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            </NavLink>
          ))}
            <NavLink className="nav-item mt-auto"onClick={onLogoutUserClicked} to="sign-in">
                <img src={logout} alt="Logout icon" />
                <span>Logout</span>
            </NavLink>
        </List>
        </div>
      );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        color='transparent'
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
        }} >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <img src={menu} alt="Toggle expand menu button" />
          </IconButton>
        <img className='nav-logo' src={logo} alt="Zanifu Logo" />
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders" >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {
            mobileOpen &&
              <div className="user-wrapper px-5 py-3">
                <p className="mb-2">Welcome,</p>
                <p>{user?.first_name} {user.last_name}</p>
                <p>{(user?.role === 'TM') ? 'Terittory Manager' : 'VSM'}</p>
              </div>
          }
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { md: `calc(100% - ${drawerWidth}px)` } }}
        >
        <Toolbar />
        <div className='container'>
          <Outlet />
        </div>
        </Box>
    /</Box>
    )
  };
  
  export default MobileNavWrapper;
  