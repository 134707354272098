import LargeLayourWrapper from "./large-layout";
import MobileNavWrapper from "./mobile-nav";

import home from "../../assets/icons/home.svg";
import archive from "../../assets/icons/archive.svg"
import book from "../../assets/icons/book-open.svg";
import dollar from "../../assets/icons/dollar-sign.svg";
import userx from "../../assets/icons/user-x.svg";
import file from "../../assets/icons/file-text.svg";
import inbox from "../../assets/icons/inbox.svg";
import bar from "../../assets/icons/bar-chart.svg";
import pin from "../../assets/icons/map-pin.svg";
import { useNavigate } from "react-router-dom";

const NavItems=[
  { title: 'Dashboard', itemId: '/dashboard', icon: home },
  { title: 'Portfolio', itemId: '/portfolio', icon: book },
  { title: 'Sales', itemId: '/sales', icon: bar },
  { title: 'Orders', itemId: '/orders', icon: archive },
  { title: 'Disbursment Statements', itemId: '/b2b', icon: file },
  { title: 'Collection Statements', itemId: '/c2b', icon: dollar },
  { title: 'Customers', itemId: '/customers', icon: inbox },
  { title: 'Blacklisted Clients', itemId: '/blacklist', icon: userx },
  { title: 'Client vists', itemId: '/vists', icon: pin },
];

const AdminLayout = () => {
  const navigate = useNavigate();
  
  if(sessionStorage.getItem('token') == null) {
    navigate('/sign-in');
  }

  return (
    <>
      <div className="d-none d-md-block">
        <LargeLayourWrapper NavItems={NavItems} />
      </div>
      <div className="d-block d-md-none">
        <MobileNavWrapper NavItems={NavItems} />
      </div>
    </>
  );
};

export default AdminLayout;
